import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Banner.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactInputMask from "react-input-mask";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../Common/SearchForm/SearchForm.css";
import Select from "react-select";
import { Images } from "../../../../assets/assets";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY_FOR_QUANTUM_MAX_GOOGLE; // For Google Campaigns
const ORIGIN = "https://quantumax.ai";

const headers = {
  "Content-Type": "application/json",
  origin: `${ORIGIN}/search`, // Replace with the actual client URL
  apikey: API_KEY, // Replace with your actual API key
};

const intialValue = {
  zipcode: "",
  fullname: "",
  email: "",
  phone: "",
  services: [],
  agreement: false,
};

const validationSchema = Yup.object().shape({
  zipcode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[^_]*$/, "Zipcode must be numbers only")
    .length(5, "Zipcode must be exactly 5 digits"),
  fullname: Yup.string()
    .required("Fullname is required")
    .matches(/^[A-Za-z\s]+$/, "Fullname  must contain only letters and spaces"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[^_]*$/, "Phone number must be digits")
    .length(14, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .nullable(),
  services: Yup.array()
    .required("Please select at least one service")
    .min(1, "Please select at least one service"),
  // services: Yup.array().nullable(),
  agreement: Yup.boolean()
    .oneOf([true], "You must agree to the terms")
    .required("Agreement is required"),
  // otpIn: Yup.boolean().nullable(),
});

const apiUrl = `${API_URL}/leads/create`;
const apiCertUrl = `${API_URL}/leads/saveCert`;

function Banner(props) {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [multiSelected, setMultiSelected] = useState([]);
  const [formValid, setFormValid] = useState(true);
  const location = useLocation();

  const formErrorRef = useRef(null);

  const [certValues, setCertValues] = useState({ certUrl: '',certToken: '',certPintUrl: '',});
  useEffect(() => 
  {
    // Dynamically load the TrustedForm script
    const tfScript = document.createElement('script');
    tfScript.type = 'text/javascript';
    tfScript.async = true;
    tfScript.src = `${document.location.protocol === 'https:' ? 'https' : 'http'}://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=${new Date().getTime()}${Math.random()}`;
    document.body.appendChild(tfScript);
  
      // Store the interval ID
      const intervalId = setInterval(() => {
        const certUrl = document.querySelector('input[name="xxTrustedFormCertUrl"]');
        const pingUrl = document.querySelector('input[name="xxTrustedFormPingUrl"]');
        const certToken = document.querySelector('input[name="xxTrustedFormToken"]');      

        if (certUrl) 
        {
          setCertValues({
            certUrl: certUrl ? certUrl.value : '',
            certToken: certToken ? certToken.value : '',
            certPintUrl: pingUrl ? pingUrl.value : '',
          });

          // Define custom values to be posted
          const certCustomValues = {
            certUrl: certUrl ? certUrl.value : '',
            certToken: certToken ? certToken.value : '',
            certPintUrl: pingUrl ? pingUrl.value : '',
            url: window?.location?.href,
          };  

          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get('source')?.toLowerCase() === 'google') {
            postCertData(certCustomValues);
          }

          clearInterval(intervalId);
        }
    }, 1000); // Check every second

    return () => clearInterval(intervalId);
    
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // maxWidth: '400px',
      margin: "0 auto",
      padding: "8px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      border: "1px ",
      color: "#888888",
      fontSize: "18px",
      fontWeight: "300",
      zIndex: 9,
      // boxShadow: !state.isFocused ? 'none' : '0 0 8px rgba(0, 0, 0, 0.3)'
    }),
  };

  const postCertData = async (data) => {
    try 
    {
      const response = await fetch(apiCertUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
    } 
    catch (error) 
    {
      console.error('Error:', error);
    }
  };
  

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      toast.dismiss();
      const queryString = window.location.search.slice(1);
      var url = window?.location?.href;
      const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...values,
          ...certValues,
          source: "Fiber Deals",
          campaignSource: queryString ? queryString : "",
          url: url ? url : "",
        }),
      };
      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response?.ok) {
            throw new Error(
              response?.status == 429
                ? "Too many requests from this IP, please try again after a day"
                : response?.status == 409
                ? 409
                : "Something went wrong"
            );
          }
          return response?.json(); // Parse the response body as JSON
        })
        .then((data) => {
          // toast.success("Thanks for contacting. Have a great day!");
          window.location.href = 'https://quantumax.ai/search';
          // navigate("/search");
          formik.resetForm();
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          if (error == "Error: 409") {
            navigate("/search");
            formik.resetForm();
          } else {
            toast.error("" + error);
          }
          console.error("Error:", error);
        })
        .finally(() => {
          localStorage.setItem("pageCheck", "pageCheck");
          setLoader(false);
        });
    },
  });

  useEffect(() => {
    if (formik.isSubmitting && formik.errors) 
    {
      const firstErrorField = Object.keys(formik.errors)[0];
      if (firstErrorField) 
      {
        setFormValid(false);
        if (formErrorRef.current) {
          formErrorRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    else
    {
      setFormValid(true);
    }
  }, [formik.isSubmitting, formik.errors]);

  return (
    <section className="baner-section">
      <Container fluid className="custom-container">
        <Row>
          <Col lg={6}>
              <div className="baner-content">
                <h1 className="theme-clr">SEARCH ENGINE</h1>
                <p className="gray-clr">
                  <span className="green-clr">FOR ALL SERVICE PROVIDERS</span> - Helping You Find the Best Deals on Internet, TV, and Phone Services.
                </p>
              </div>
              <hr/>
							<div className='lead-img-container'>
								<img src={Images.leadprovider0} className="img-fluid" alt="img" />
							</div>
					</Col>
          <Col lg={6} ref={props?.section1Ref}>
            <div className="form_content_banner mt-2">
              <form onSubmit={formik.handleSubmit}>
                <h6 className="green-clr text-center mb-xl-4 mb-3">
                  Enter the details to get started
                </h6>
                   
                <div ref={formErrorRef}>
                  {!formValid && (
                    <div className="for-red">Please complete all required fields to proceed.</div>
                  )}
                </div>
            
                <Row>
                  <Col lg={6}>
                    <div className="form-group mb-xxl-3 mb-2">
                      
                      <label for="exampleInputPassword1">
                        Your Zipcode<span className="red_staric">*</span>
                      </label>
                      <ReactInputMask
                        className={`form-control ${
                          formik.touched.zipcode && formik.errors.zipcode
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        mask="99999"
                        {...formik.getFieldProps("zipcode")}
                        placeholder="Enter your zipcode"
                      />
                      {formik.touched.zipcode && formik.errors.zipcode && (
                        <div className="for-red">{formik.errors.zipcode}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group mb-xxl-3 mb-2">
                      <label for="exampleInputPassword1">
                        Your Fullname<span className="red_staric">*</span>
                      </label>
                      <input
                        type="fullname"
                        placeholder="Enter your fullname"
                        {...formik.getFieldProps("fullname")}
                        className={`form-control ${
                          formik.touched.fullname && formik.errors.fullname
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.touched.fullname && formik.errors.fullname && (
                        <div className="for-red">{formik.errors.fullname}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group mb-xxl-3 mb-2">
                      <label for="exampleInputPassword1">
                        Your Email<span className="red_staric">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        {...formik.getFieldProps("email")}
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="for-red">{formik.errors.email}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group mb-xxl-3 mb-2">
                      <label for="exampleInputPassword1">
                        Your Phone<span className="red_staric">*</span>
                      </label>
                      <ReactInputMask
                        className={`form-control ${
                          formik.touched.phone && formik.errors.phone
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        mask="(999) 999-9999"
                        {...formik.getFieldProps("phone")}
                        placeholder="Enter your phone number"
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className="for-red">{formik.errors.phone}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group  mb-xxl-3 mb-2">
                      <label className="my-2" for="exampleInputPassword1">
                        Which Services Are You Interested In ?
                        <span className="red_staric">*</span>
                      </label>
                      <Select
                        // className="custom-dropdown"
                        className={`${
                          formik.touched.services && formik.errors.services
                            ? "is-invalid "
                            : ""
                        } css-1wlpnw2-control`}
                        styles={customStyles}
                        name="services"
                        options={[
                          { value: "cableTv", label: "CableTv" },
                          { value: "internet", label: "Internet" },
                          { value: "phone", label: "Phone" },
                          { value: "freeInternet", label: "Free Internet" },
                          { value: "other", label: "Other" },
                        ]}
                        isMulti
                        onChange={(selectedOptions) => {
                          selectedOptions?.length > 0
                            ? setMultiSelected([...selectedOptions])
                            : setMultiSelected([]);
                          formik.setFieldValue(
                            "services",
                            selectedOptions?.length > 0
                              ? selectedOptions.map((option) => option?.value)
                              : []
                          );
                        }}
                        value={multiSelected}
                      />
                      {formik.touched.services && formik.errors.services && (
                        <div className="for-red">{formik.errors.services}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                    <div className="mini-text-wrap d-flex">
                          <div className="d-flex flex-column align-items-start">
                            <label className="d-flex custom-control overflow-checkbox relative">
                              <input
                                name="agreement"
                                className="overflow-control-input"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.agreement}
                              />
                              <span className="overflow-control-indicator "></span>
                              <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2"></span>
                            </label>
                            <p className="Submitting_form cri-pointer mb-1">
                            </p>
                          </div>

                          <div>
                            {formik.touched.agreement && formik.errors.agreement && (
                              <p className="mt-2">
                                <div className="for-red ">
                                  {formik.errors.agreement}
                                </div>
                              </p>
                            )}
                            <p className="mt-2">
                              By checking this box and clicking "SUBMIT NOW" I represent that I am the line subscriber or primary user of the phone number above (including my wireless number if provided) and provide my express consent authorizing “Quantumax” to contact me by telephone (including text messages), delivered via automated technology to the phone number above regarding phone carrier and internet service products and/or offerings even if I am on a Federal, State or Do-Not-Call registry. I understand that my consent is not required to continue with my application or is a condition to search for phone carrier and internet products and/offerings. I understand and agree that this site uses third-party visit recording technology, including, but not limited to, Trusted Form and Jornaya. I understand I can revoke consent at any time. To skip and continue without sharing your information with our phone and internet service providers/partners, <b><a target="_blank" className="link-Custom-Color" href="tel:+18335838990">click here</a></b> 
                            </p>
                            <p className="mt-2">
                              <b>
                                For SMS messages campaigns Msg. & Data Rates May Apply. Reply HELP for help. Reply STOP to cancel.
                              </b>
                            </p>
                            <p className="mt-2">
                              By clicking “SUBMIT NOW” I represent I am a U.S. Resident over the age of 18, understand and agree to the <b><a target="_blank" className="link-Custom-Color" href="https://quantumax.ai/page/privacy-policy">Privacy Policy</a></b>, <b><a target="_blank" className="link-Custom-Color" href="https://quantumax.ai/page/terms">Terms & Conditions</a></b> and <b><a target="_blank" className="link-Custom-Color" href="https://quantumax.ai/page/california-privacy-notice">California Privacy Notice</a></b> and agree to receive email promotions from Quantumax and our marketing partners. 
                            </p>
                          </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="d-flex  justify-content-end mt-xl-4 mt-3 mb-xl-4 mb-3">
                      {loader ? (
                        <button disabled className="btn theme-btn d-flex ">
                          <span className="ml_9"> Loading...</span>
                          <div className="spinner"></div>
                        </button>
                      ) : (
                        <button type="submit" className="btn theme-btn d-flex ">
                          SUBMIT NOW
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;
