import React, { useState, useEffect } from "react";
import { useLocation  } from 'react-router-dom';
import Loader from '../../Common/Loader/LoaderQuantumMax';

const SrchChamelean = () => {
  const [loader, setLoader] = useState(true);
  const [provider, setProvider] = useState('all');

  const location = useLocation();

  useEffect(() => {
    const fetchedUrl = window.location.href;

    const params = new URL(fetchedUrl).searchParams;

    const provider = params.get('provider');
    
    let url = 'https://quantumax.ai/search';
    if(provider)
    {
      url = url+'?provider='+provider
    }

    window.location.href = url;
  }, []);

  return (
    <Loader/>
  );
};

export default SrchChamelean;
